import axios from 'axios'
import Cookies from 'js-cookie'
import { useSpinnerStore } from '@/store/spinner' // import the instance
import { useToastStore } from '@/store/toast'

const url = 'https://apotikbasmahali.my.id/backend/api' //WEB APACHE
// const url = 'http://localhost/apotekbasmahali/backend/api' //WEB APACHE
const baseUrl = 'https://apotikbasmahali.my.id/backend/api' // PHP LIVE SERVER

const token =
    Cookies.get('user') != undefined
        ? JSON.parse(Cookies.get('user')).token
        : ''

const http = axios.create({
    baseURL: url,
    headers: {
        Authorization: 'Bearer ' + token,
        Accept: 'application/json',
    },
    withCredentials: true,
})

http.interceptors.request.use(
    (x) => {
        // to avoid overwriting if another interceptor
        // already defined the same object (meta)
        useSpinnerStore().loading(true)

        return x
    },
    (error) => {
        useSpinnerStore().loading(false)
        return Promise.reject(error.message)
    },
)

http.interceptors.response.use(
    (x) => {
        useSpinnerStore().loading(false)

        return x
    },
    (error) => {
        useSpinnerStore().loading(false)
        if (error.response.status === 401) {
            useToastStore().showToast({
                show: true,
                msg: 'Silahkan login',
                classType: 'bg-danger',
            })
            Cookies.remove('user')
        }
        return Promise.reject(error)
    },
)

export { http, url }
