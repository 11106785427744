export default [
    {
        component: 'CNavItem',
        name: 'Dashboard',
        to: '/dashboard',
        roles: ['SUPER_ADMIN', 'ADMIN', 'KASIR'],
        icon: 'cil-speedometer',
    },
    {
        component: 'CNavTitle',
        name: 'Menu',
        roles: ['SUPER_ADMIN', 'ADMIN', 'KASIR'],
    },
    {
        component: 'CNavItem',
        name: 'Pembelian',
        to: '/pembelian',
        roles: ['SUPER_ADMIN', 'ADMIN'],
        icon: 'cil-pencil',
    },
    {
        component: 'CNavItem',
        name: 'Penjualan',
        to: '/penjualan',
        roles: ['SUPER_ADMIN', 'ADMIN', 'KASIR'],
        icon: 'cil-speedometer',
    },
    {
        component: 'CNavItem',
        name: 'Setoran',
        to: '/setoran',
        roles: ['SUPER_ADMIN', 'ADMIN', 'KASIR'],
        icon: 'cil-cursor',
    },
    {
        component: 'CNavGroup',
        name: 'Data Obat',
        icon: 'cil-list',
        roles: ['SUPER_ADMIN', 'ADMIN'],
        items: [
            {
                component: 'CNavItem',
                name: 'Obat',
                to: '/m-obat',
                roles: ['SUPER_ADMIN', 'ADMIN', 'KASIR'],
            },
            {
                component: 'CNavItem',
                name: 'Kartu Stok',
                to: '/kartu-stok',
                roles: ['SUPER_ADMIN', 'ADMIN'],
            },
            {
                component: 'CNavItem',
                name: 'Stok Opname',
                to: '/stok-opname',
                roles: ['SUPER_ADMIN', 'ADMIN'],
            },
            {
                component: 'CNavItem',
                name: 'Harga Obat',
                to: '/harga-obat',
                roles: ['SUPER_ADMIN', 'ADMIN', 'KASIR'],
            },
            {
                component: 'CNavItem',
                name: 'Harga',
                to: '/m-harga',
                roles: ['SUPER_ADMIN', 'ADMIN', 'KASIR'],
            },
        ],
    },
    {
        component: 'CNavGroup',
        name: 'Data Master',
        icon: 'cil-puzzle',
        roles: ['SUPER_ADMIN', 'ADMIN'],
        items: [
            {
                component: 'CNavItem',
                name: 'Supplier',
                to: '/m-supplier',
                roles: ['SUPER_ADMIN', 'ADMIN'],
            },
            {
                component: 'CNavItem',
                name: 'Principal',
                to: '/m-principal',
                roles: ['SUPER_ADMIN'],
            },
            {
                component: 'CNavItem',
                name: 'Kategori',
                to: '/m-kategori',
                roles: ['SUPER_ADMIN'],
            },
            {
                component: 'CNavItem',
                name: 'Satuan',
                to: '/m-satuan',
                roles: ['SUPER_ADMIN'],
            },
            {
                component: 'CNavItem',
                name: 'Tindakan',
                to: '/m-tindakan',
                roles: ['SUPER_ADMIN', 'ADMIN', 'KASIR'],
            },
            {
                component: 'CNavItem',
                name: 'User',
                to: '/m-user',

                roles: ['SUPER_ADMIN'],
            },
            {
                component: 'CNavItem',
                name: 'MShift',
                to: '/m-shift',
                roles: ['SUPER_ADMIN'],
            },
        ],
    },
    {
        component: 'CNavGroup',
        name: 'History',
        icon: 'cil-calculator',
        roles: ['SUPER_ADMIN', 'ADMIN', 'KASIR'],
        items: [
            {
                component: 'CNavItem',
                name: 'Pembelian',
                to: '/history-pembelian',
                roles: ['SUPER_ADMIN', 'ADMIN'],
            },
            {
                component: 'CNavItem',
                name: 'Penjualan',
                to: '/history-penjualan',
                roles: ['SUPER_ADMIN', 'ADMIN', 'KASIR'],
            },
        ],
    },
    {
        component: 'CNavGroup',
        name: 'Surat',
        icon: 'cil-notes',
        roles: ['SUPER_ADMIN', 'ADMIN'],
        items: [
            {
                component: 'CNavItem',
                name: 'Pemesanan',
                to: '/pemesanan',
                roles: ['SUPER_ADMIN', 'ADMIN'],
            },
        ],
    },
    {
        component: 'CNavItem',
        name: 'Jual Obat',
        icon: 'cil-notes',
        roles: ['SUPER_ADMIN', 'PELANGGAN'],
        to: '/pelanggan/m-obat',
    },
    {
        component: 'CNavGroup',
        name: 'Laporan',
        icon: 'cil-star',
        roles: ['SUPER_ADMIN'],
        items: [
            {
                component: 'CNavItem',
                name: 'Setoran',
                to: '/laporan/setoran',
                roles: ['SUPER_ADMIN'],
            },
            {
                component: 'CNavItem',
                name: 'Obat',
                to: '/laporan/obat',
                roles: ['SUPER_ADMIN'],
            },
            {
                component: 'CNavItem',
                name: 'Pembelian',
                to: '/laporan/pembelian',
                roles: ['SUPER_ADMIN'],
            },
            {
                component: 'CNavItem',
                name: 'Penjualan',
                to: '/laporan/penjualan',
                roles: ['SUPER_ADMIN'],
            },
        ],
    },
]
